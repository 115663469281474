import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
  return (
    <Layout>
      <div className="jumbotron">
        <Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/roadmap" />
      </div>        
      <div className="roadmapGridBox">

        <div className="gridFraction displayNonInSmallDevices">OER</div>
        <div className="gridFraction displayNonInSmallDevices">Werktitel</div>
        <div className="gridFraction displayNonInSmallDevices">Online</div>
        <div className="gridFraction displayNonInSmallDevices">Print</div>
        <div className="gridFraction displayNonInSmallDevices">Termin</div>

        <div className="gridFraction">Nr. 21</div>
        <div className="gridFraction">Die Winterreise</div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2023</div>

        <div className="gridFraction">Nr. 20</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/Px7epURXWsBLoE4fKzFBsW/">Chorsingen? Easy-Peasy! 12 einfache Chorsätze zum Loslegen für das Klassenmusizieren mit gleichen Stimmen und eine Klavierbegleitung.</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2023</div>

        <div className="gridFraction">Nr. 19</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/Px7epURXWsBLoE4fKzFBsW/">Konzertsatz für Violine und Orchester</a></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2021</div>

        <div className="gridFraction">Nr. 18/3</div>
        <div className="gridFraction">Walzer Op. 39 arrangiert für Orchester</div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2021</div>

        <div className="gridFraction">Nr. 18/2</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/E1dainJq33bSghxHgr3Mfc">Carmen-Fantasie von Ulrich Kaiser</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2017</div>

        <div className="gridFraction">Nr. 18/1</div>
        <div className="gridFraction"><Link to="/oer-johannes-brahms-ungarischer-tanz-5">Johannes Brahms. Ungarischer Tanz Nr. 5 arrangiert und mit einer Einleitung versehen von Ulrich Kaiser</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><Link to="/oer-johannes-brahms-ungarischer-tanz-5"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2017</div>

        <div className="gridFraction">Nr. 17/2</div>
        <div className="gridFraction">J. Brahms. Walzer aus Op. 39 arrangiert für Violine und Akkordeon</div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2021</div>

        <div className="gridFraction">Nr. 17/1</div>
        <div className="gridFraction">
          <a href="/oer-walzer-von-brahms-arrangiert-fuer-zwei-violinen"> J. Brahms. Walzer aus Op. 39 arrangiert für 2 Violinen</a></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2021</div>

        <div className="gridFraction">Nr. 16</div>
        <div className="gridFraction"><Link to="/oer-orchesterinstrumente-transponierende-instrumente">Orchesterinstrumente. Transponierende Instrumente</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><Link to="/transponierendeinstrumente"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2016</div>

        <div className="gridFraction">Nr. 15</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/NvCYdJuLSa7wFXBs1xf9k8/schule-filmmusik-openbook">Filmmusik</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="rotes Nein-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2020</div>

        <div className="gridFraction">Nr. 14/2</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/gyZt9Vu2qZn5bysPPdS9M3/happy-birthday-to-you-fuer-vierstimmig-gemischten-chor">Kanon und 8 Variationen über »Happy Birthday to You« für vierstimmig gemischten Chor</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/Tick.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2017</div>

        <div className="gridFraction">Nr. 14/1</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/qbTvWPnFEEWPRMuStHHoqf/">Thema und 12 Variationen über »Happy Birthday to You« für zwei beliebige Melodieinstrumente</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="https://www.breitkopf.com/work/8989/thema-und-12-variationen-uber-happy-birthday"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2017 (1.1.)</div>

        <div className="gridFraction">Nr. 13</div>
        <div className="gridFraction"><Link to="/oer-formenlehre-der-musik">Formenlehre</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2019</div>

        <div className="gridFraction">Nr. 12</div>
        <div className="gridFraction"><Link to="/oer-harmonielehre-reloaded-eine-praktische-anleitung-fuer-musikerinnen-und-musiker">Harmonielehre #reloaded</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2016</div>

        <div className="gridFraction">Nr. 11</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/KV9CighBEVoAW2D8Nh4iPN/bach-inventionen-plus-one">Inventionen plus One. Kammermusik mit Johann Sebastian Bach</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2015</div>

        <div className="gridFraction">Nr. 10</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/iaSQFz196ND5CadjYekn7k/das-kinderhauslied">Das Kinderhauslied</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2015</div>

        <div className="gridFraction">Nr. 09/4</div>
        <div className="gridFraction"><Link to="/oer-band-impulse-heft-4-gehoerbildung-und-musiklehre">Band-Impulse. Heft 4: Gehörbildung und Musiklehre. Übungen in Dur</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2016</div>

        <div className="gridFraction">Nr. 09/3</div>
        <div className="gridFraction"><Link to="/oer-band-impulse-heft-3-pattern-und-styles-1">Band-Impulse. Heft 3: Pattern und Styles 1</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2015</div>

        <div className="gridFraction">Nr. 09/2</div>
        <div className="gridFraction"><Link to="/oer-band-impulse-heft-2-elise-in-black">Band-Impulse. Heft 2: Elise In Black</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2015</div>

        <div className="gridFraction">Nr. 09/1</div>
        <div className="gridFraction">
          <a href="https://openmusic.academy/docs/q9RGjWnB5J3nV3rTHRpgif/bandklasse-mitspielsatz-that-image">Band-Impulse. Heft 1: That Image</a>
        </div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2015</div>

        <div className="gridFraction">Nr. 08</div>
        <div className="gridFraction"><Link to="/oer-harmonielehre-harmonie-und-form-fuer-kompositionen-in-dur">Harmonielehre. Harmonie und Form für Kompositionen in Dur</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2015</div>

        <div className="gridFraction">Nr. 07</div>
        <div className="gridFraction"><Link to="/oer-kostenlose-software-fuer-den-musikunterricht">Kostenlose Software für den Musikunterricht</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2014</div>

        <div className="gridFraction">Nr. 06</div>
        <div className="gridFraction"><Link to="/oer-ludwig-van-beethoven-die-fuenfte-sinfonie">Ludwig van Beethoven. Die 5. Sinfonie</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Zeichen" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Zeichen" /></div>
        <div className="gridFraction displayNonInSmallDevices">2014</div>

        <div className="gridFraction">Nr. 05</div>
        <div className="gridFraction"><Link to="/oer-lamentobass-musik-aus-vier-jahrhunderten">Lamentobass. Musik aus vier Jahrhunderten</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Icon" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Icon" /></div>
        <div className="gridFraction displayNonInSmallDevices">2013</div>

        <div className="gridFraction">Nr. 04</div>
        <div className="gridFraction"><Link to="/oer-praktische-harmonielehre-sequenzen-fuer-kinder">Praktische Harmonielehre: Sequenzen</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Icon" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Icon" /></div>
        <div className="gridFraction displayNonInSmallDevices">2013</div>

        <div className="gridFraction">Nr. 03</div>
        <div className="gridFraction"><Link to="/oer-intervalle-und-akkorde">Intervalle und Akkorde</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Icon" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Icon" /></div>
        <div className="gridFraction displayNonInSmallDevices">2012</div>

        <div className="gridFraction">Nr. 02</div>
        <div className="gridFraction"><Link to="/oer-johann-sebastian-bach-ein-superstar-gestern-und-heute">Johann Sebastian Bach</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Icon" /></div>
        <div className="gridFraction"><Link to="/print"><img src="/Images/Tick.png" alt="grünes Ja-Icon" /></Link></div>
        <div className="gridFraction displayNonInSmallDevices">2011</div>
    
        <div className="gridFraction">Nr. 01</div>
        <div className="gridFraction"><Link to="/oer-sonate-und-sinfonie-ein-altes-thema-auf-neuen-wegen">Sonate und Sinfonie (2. überarbeitete Aufl. 2012)</Link></div>
        <div className="gridFraction displayNonInSmallDevices"><img src="/Images/Tick.png" alt="grünes Ja-Icon" /></div>
        <div className="gridFraction"><img src="/Images/Cross.png" alt="rotes Nein-Icon" /></div>
        <div className="gridFraction displayNonInSmallDevices">2011</div>

      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Roadmap</title>
        <meta name="description" content="Hier finden Sie eine Übersicht über die fertiggestellten und in Planung befindlichen OpenBooks (OER) von Ulrich Kaiser." />
        <meta name="keywords" content="Roadmap, Planungen, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  )
}